.text-justify-inter-word {
  text-align: justify;
  text-justify: inter-word;
}

// css class width, heigt, margin, padding
@for $i from 0 through 9 {
    $p1: $i * 100;
    $p2: $i * 100 + 50;
    $p3: $i * 10;
    $p4: $i * 10 + 5;
  
    .tb-tbody-mh-#{$p1} {
      tbody {
        max-height: #{$p1}px;
      }
    }
  
    .tb-tbody-mh-#{$p2} {
      tbody {
        max-height: #{$p2}px;
      }
    }
  
    .height-#{$p1} {
      height: #{$p1}px;
    }
  
    .height-#{$p2} {
      height: #{$p2}px;
    }
  
    .height-#{$p3} {
      height: #{$p3}px;
    }
  
    .height-#{$p4} {
      height: #{$p4}px;
    }
  
    .width-#{$p1} {
      width: #{$p1}px;
    }
  
    .width-#{$p2} {
      width: #{$p2}px;
    }
  
    .min-width-#{$p1} {
      min-width: #{$p1}px;
    }
  
    .min-width-#{$p2} {
      min-width: #{$p2}px;
    }
  
    .min-width-#{$p3} {
      min-width: #{$p3}px;
    }
  
    .min-width-#{$p4} {
      min-width: #{$p4}px;
    }

    .max-width-#{$p1} {
      max-width: #{$p1}px;
    }

    .max-width-#{$p2} {
      max-width: #{$p2}px;
    }
  
    .min-height-#{$p1} {
      min-height: #{$p1}px;
    }
  
    .min-height-#{$p2} {
      min-height: #{$p2}px;
    }
  
    .min-height-#{$p3} {
      min-height: #{$p3}px;
    }
  
    .min-height-#{$p4} {
      min-height: #{$p4}px;
    }

    .max-height-#{$p1} {
      max-height: #{$p1}px;
    }
  
    .mg-t-#{$p3} {
      margin-top: #{$p3}px !important;
    }
  
    .mg-b-#{$p3} {
      margin-bottom: #{$p3}px !important;
    }
  
    .mg-l-#{$p3} {
      margin-left: #{$p3}px !important;
    }
  
    .mg-r-#{$p3} {
      margin-right: #{$p3}px !important;
    }
  
    .mg-tb-#{$p3} {
      margin-top: #{$p3}px !important;
      margin-bottom: #{$p3}px !important;
    }
  
    .mg-lr-#{$p3} {
      margin-left: #{$p3}px !important;
      margin-right: #{$p3}px !important;
    }
  
    .pd-#{$p3} {
      padding: #{$p3}px !important;
    }
  
    .pd-t-#{$p3} {
      padding-top: #{$p3}px !important;
    }
  
    .pd-b-#{$p3} {
      padding-bottom: #{$p3}px !important;
    }
  
    .pd-l-#{$p3} {
      padding-left: #{$p3}px !important;
    }
  
    .pd-r-#{$p3} {
      padding-right: #{$p3}px !important;
    }
  
    .pd-tb-#{$p3} {
      padding-top: #{$p3}px !important;
      padding-bottom: #{$p3}px !important;
    }
  
    .pd-lr-#{$p3} {
      padding-left: #{$p3}px !important;
      padding-right: #{$p3}px !important;
    }
  
    .mg-#{$p3} {
      margin: #{$p3}px !important;
    }
  
    .mg-t-#{$p4} {
      margin-top: #{$p4}px !important;
    }
  
    .mg-b-#{$p4} {
      margin-bottom: #{$p4}px !important;
    }
  
    .mg-l-#{$p4} {
      margin-left: #{$p4}px !important;
    }
  
    .mg-r-#{$p4} {
      margin-right: #{$p4}px !important;
    }
  
    .mg-tb-#{$p4} {
      margin-top: #{$p4}px !important;
      margin-bottom: #{$p4}px !important;
    }
  
    .mg-lr-#{$p4} {
      margin-left: #{$p4}px !important;
      margin-right: #{$p4}px !important;
    }
  
    .pd-t-#{$p4} {
      padding-top: #{$p4}px !important;
    }
  
    .pd-b-#{$p4} {
      padding-bottom: #{$p4}px !important;
    }
  
    .pd-l-#{$p4} {
      padding-left: #{$p4}px !important;
    }
  
    .pd-r-#{$p4} {
      padding-right: #{$p4}px !important;
    }
  
    .pd-tb-#{$p4} {
      padding-top: #{$p4}px !important;
      padding-bottom: #{$p4}px !important;
    }
  
    .pd-lr-#{$p4} {
      padding-left: #{$p4}px !important;
      padding-right: #{$p4}px !important;
    }

    .flex-gap-#{$p3} {
      gap: #{$p3}px;
    }
  
    .flex-gap-#{$p4} {
      gap: #{$p4}px;
    }
}

.text-error {
  color: red;
}

.text-green {
  color: green;
}

.text-12 {
  font-size: 12px !important;
}

.text-13 {
  font-size: 13px !important;
}

.text-14 {
  font-size: 14px !important;
}

.text-50 {
  font-size: 50px !important;
}

.my-paginagion {
  .page-item:not(:first-child) .page-link {
    margin-left: inherit;
  }

  .page-link {
    padding: inherit;
  }

  .page-item:first-child a {
    border-left: 1px solid #ddd;
  }
}

.faq-head {
  cursor: pointer;
      padding: 5px 14px;
          border-bottom: 1px dashed #DADDE2;
          padding-right: 44px;
 }

.faq-acc-content {
    background: #F5F5F5 0% 0% no-repeat padding-box;
    padding: 18px 28px 21px 14px;
    overflow-y: scroll;
    display: flex;
    align-items: flex-start;
}

.faq-title-3{
    margin: 0;
  position: relative;
  padding-left: 46px;
}

.faq-title-3 span{
    background: #FCB900 0% 0% no-repeat padding-box;
  display: inline-block;
  width: 32px;
  height: 32px;
  font: normal normal bold 16px/26px Yu Gothic;
  letter-spacing: 0px;
  color: #1A2C44;
  text-align: center;
  line-height: 32px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

.faq-acc-content .a-label {
  background: #FCB900 0% 0% no-repeat padding-box;
  // display: inline-block;
  width: 32px;
  height: 32px;
  font: normal normal bold 16px/32px Yu Gothic;
  letter-spacing: 0px;
  color: #1A2C44;
  text-align: center;
  line-height: 32px;
  border-radius: 50%;
  margin-right: 15px;
}

.messages-register {
  font: normal normal bold 14px/30px Yu Gothic;
}


.faq-acc-content-right {
  flex: 1;
}

.faq-acc-head .title-2 span{
	display: inline-block;
    width: 32px;
    height: 32px;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    text-align: center;
    line-height: 32px;
    border-radius: 50%;
    font: normal normal bold 16px/26px Yu Gothic;
    margin-right: 21px;
    line-height: 32px;
}

.faq-acc-head {
  // position: relative;
  // cursor: pointer;
      padding: 28px 14px 13px 14px;
          border-bottom: 1px dashed #DADDE2;
          padding-right: 18px;
 }

.faq-acc-head .btnImg{
  text-align: right;
 }

.rowContainer {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  // margin-right: calc(-.5 * var(--bs-gutter-x));
  // margin-left: calc(-.5 * var(--bs-gutter-x));
}
.cursor-pointer {
  cursor: pointer
}

.cursor-pointer-none {
  cursor: default !important;
  
  * {
    cursor: default !important;
  }
}

.pre-wrap {
  white-space: pre-wrap;
}

// css buttom
.btn-default-2 {
  background-color: #fcb900!important;
  color: #fff!important;
  border: none;
}

input::-ms-reveal,
      input::-ms-clear {
        display: none;
      }
input {
  padding-right: 15px;
}

input[name=password] {
  padding-right: 45px;
}

input[type=password] {
  padding-right: 45px;
}

input[name=passwordConfirm] {
  padding-right: 45px;
}

.ic-new {
  width: 50px;
  object-fit: contain;
  // margin-top: -30px;
}

.text-hover-link:hover {
  color: #FCB900 !important;
  cursor: pointer;
}

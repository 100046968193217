html.hc-nav-yscroll {
	overflow-y: scroll
}

body.hc-nav-open {
	overflow: hidden;
	position: fixed;
	width: 100%;
	min-height: 100%
}

.hc-offcanvas-nav {
	visibility: hidden;
	display: none;
	position: fixed;
	top: 0;
	height: 100%;
	z-index: 9999
}

.hc-offcanvas-nav.is-ios * {
	cursor: pointer!important
}

.hc-offcanvas-nav .nav-container {
	position: fixed;
	z-index: 9998;
	top: 0;
	width: 280px;
	height: 100%;
	max-width: 100%;
	max-height: 100%;
	box-sizing: border-box;
	/* transition: transform .4s ease */
}

.hc-offcanvas-nav .nav-wrapper {
	width: 100%;
	height: 100%;
	max-height: 100vh;
	overscroll-behavior: none;
	box-sizing: border-box
}

.hc-offcanvas-nav .nav-content {
	height: 100%;
	max-height: 100vh
}

.hc-offcanvas-nav .nav-wrapper-0>.nav-content {
	overflow: scroll;
	overflow-x: visible;
	overflow-y: auto;
	box-sizing: border-box
}

.hc-offcanvas-nav ul {
	list-style: none;
	margin: 0;
	padding: 0
}

.hc-offcanvas-nav li {
	position: relative;
	display: block
}

.hc-offcanvas-nav li.level-open>.nav-wrapper {
	visibility: visible
}

.hc-offcanvas-nav input[type="checkbox"] {
	display: none
}

.hc-offcanvas-nav label {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10;
	cursor: pointer
}

.hc-offcanvas-nav a {
	position: relative;
	display: block;
	box-sizing: border-box;
	cursor: pointer
}

.hc-offcanvas-nav a,
.hc-offcanvas-nav a:hover {
	text-decoration: none
}

.hc-offcanvas-nav.disable-body::after,
.hc-offcanvas-nav .nav-wrapper::after {
	content: '';
	position: fixed;
	z-index: 9990;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	overscroll-behavior: none;
	visibility: hidden;
	opacity: 0;
	/* transition: visibility 0 ease .4s, opacity .4s ease */
}

.hc-offcanvas-nav.disable-body.nav-open::after,
.hc-offcanvas-nav .sub-level-open::after {
	visibility: visible;
	opacity: 1;
	/* transition-delay: .05s */
}

.hc-offcanvas-nav:not(.nav-open)::after {
	pointer-events: none
}

.hc-offcanvas-nav.nav-levels-expand .nav-content {
	overflow: scroll;
	overflow-x: visible;
	overflow-y: auto;
	box-sizing: border-box
}

.hc-offcanvas-nav.nav-levels-expand .nav-wrapper::after {
	display: none
}

.hc-offcanvas-nav.nav-levels-expand ul .nav-wrapper {
	min-width: 0;
	max-height: 0;
	overflow: hidden;
	/* transition: height 0 ease .4s */
}

.hc-offcanvas-nav.nav-levels-expand .level-open>.nav-wrapper {
	max-height: none
}

.hc-offcanvas-nav.nav-levels-overlap .nav-content {
	overflow: scroll;
	overflow-x: visible;
	overflow-y: auto;
	box-sizing: border-box
}

.hc-offcanvas-nav.nav-levels-overlap ul .nav-wrapper {
	position: absolute;
	z-index: 9999;
	top: 0;
	height: 100%;
	visibility: hidden;
	/* transition: visibility 0 ease .4s, transform .4s ease */
}

.hc-offcanvas-nav.nav-levels-overlap ul li.nav-parent {
	position: static
}

.hc-offcanvas-nav.nav-levels-overlap ul li.level-open>.nav-wrapper {
	visibility: visible;
	transform: translate3d(0, 0, 0);
	/* transition: transform .4s ease */
}

.hc-offcanvas-nav.nav-position-left {
	left: 0
}

.hc-offcanvas-nav.nav-position-left .nav-container {
	left: 0;
	transform: translate3d(-280px, 0, 0)
}

.hc-offcanvas-nav.nav-position-left.nav-levels-overlap li .nav-wrapper {
	left: 0;
	transform: translate3d(-100%, 0, 0)
}

.hc-offcanvas-nav.nav-position-right {
	right: 0
}

.hc-offcanvas-nav.nav-position-right .nav-container {
	right: 0;
	transform: translate3d(280px, 0, 0)
}

.hc-offcanvas-nav.nav-position-right.nav-levels-overlap li .nav-wrapper {
	right: 0;
	transform: translate3d(100%, 0, 0)
}

.hc-offcanvas-nav.nav-position-top {
	top: 0
}

.hc-offcanvas-nav.nav-position-top .nav-container {
	top: 0;
	width: 100%;
	height: auto;
	transform: translate3d(0, -100%, 0)
}

.hc-offcanvas-nav.nav-position-top.nav-levels-overlap li .nav-wrapper {
	left: 0;
	transform: translate3d(0, -100%, 0)
}

.hc-offcanvas-nav.nav-position-bottom {
	top: auto;
	bottom: 0
}

.hc-offcanvas-nav.nav-position-bottom .nav-container {
	top: auto;
	bottom: 0;
	width: 100%;
	height: auto;
	transform: translate3d(0, 100%, 0)
}

.hc-offcanvas-nav.nav-position-bottom.nav-levels-overlap li .nav-wrapper {
	left: 0;
	transform: translate3d(0, 100%, 0)
}

.hc-offcanvas-nav.nav-open[class*='hc-nav-'] div.nav-container {
	transform: translate3d(0, 0, 0)
}

.hc-nav-trigger {
	position: absolute;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	display: none;
	top: 17px;
	left: 15px;
	z-index:2;
	width: 30px;
	min-height: 24px
}

/* .hc-nav-trigger span {
	width: 25px;
	top: 50%;
	left: 15px;
	transform: translateY(-50%);
	transform-origin: 50% 50%
} */

/* .hc-nav-trigger span,
.hc-nav-trigger span::before,
.hc-nav-trigger span::after {
	display: block;
	position: absolute;
	left: 0;
	height: 2px;
	background: #333;
	transition: all .2s ease
} */

.hc-nav-trigger span::after {
	content: '';
	display: block;
	background: url('/public/images/bars-solid.svg');
	width: 25px;
	height: 30px;
}

/* .hc-nav-trigger span::before,
.hc-nav-trigger span::after {
	content: '';
	width: 100%
}

.hc-nav-trigger span::before {
	top: -8px
}

.hc-nav-trigger span::after {
	bottom: -8px
} */

.hc-nav-trigger.toggle-open span {
	background: rgba(0, 0, 0, 0);
	transform: rotate(45deg)
}

.hc-nav-trigger.toggle-open span::before {
	transform: translate3d(0, 10px, 0)
}

.hc-nav-trigger.toggle-open span::after {
	transform: rotate(-90deg) translate3d(10px, 0, 0)
}

.hc-offcanvas-nav::after,
.hc-offcanvas-nav .nav-wrapper::after {
	background: rgba(0, 0, 0, 0.3)
}

.hc-offcanvas-nav .nav-container,
.hc-offcanvas-nav .nav-wrapper,
.hc-offcanvas-nav ul {
	background: #fff
}

.hc-offcanvas-nav h2 {
	font-size: 19px;
	font-weight: 400;
	text-align: left;
	padding: 20px 17px;
	color: #333
}

.hc-offcanvas-nav a {
	padding: 14px 17px;
	font-size: 15px;
	color: #333;
	z-index: 1;
	background: rgba(0, 0, 0, 0);
	border-bottom: 1px solid #ddd
}

.hc-offcanvas-nav:not(.touch-device) a:hover {
	background: #fff
}

.hc-offcanvas-nav ul:first-of-type:not(:first-child)>li:first-child:not(.nav-back):not(.nav-close)>a {
	border-top: 1px solid #2c5d8f;
	margin-top: -1px
}

.hc-offcanvas-nav li {
	text-align: left
}

.hc-offcanvas-nav li.nav-close a,
.hc-offcanvas-nav li.nav-back a {
	background: #fff;
	border-top: 1px solid #fff;
	border-bottom: 1px solid #fff
}

.hc-offcanvas-nav li.nav-close a:hover,
.hc-offcanvas-nav li.nav-back a:hover {
	background: #fff
}

.hc-offcanvas-nav li.nav-parent a {
	padding-right: 58px
}

.hc-offcanvas-nav li.nav-close span,
.hc-offcanvas-nav li.nav-parent span.nav-next,
.hc-offcanvas-nav li.nav-back span {
	width: 100%;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	/* transition: background .2s ease */
}

.hc-offcanvas-nav li.nav-parent span.nav-next {
	width: 15%;
}

.hc-offcanvas-nav li.nav-close span::after {
	content: '';
	display: block;
	background: url('/public/images/outdent-solid.svg');
	width: 25px;
	height: 25px;
}

.hc-offcanvas-nav a[href]:not([href="#"])>span.nav-next {
	border-left: 1px solid #2c5d8f
}

.hc-offcanvas-nav span.nav-next::before,
.hc-offcanvas-nav li.nav-back span::before {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	width: 8px;
	height: 8px;
	margin-left: -2px;
	box-sizing: border-box;
	border-top: 2px solid #333;
	border-left: 2px solid #333;
	transform-origin: center
}

.hc-offcanvas-nav .nav-parent.level-open span.nav-next::before {
	border-top: 2px solid #333;
	border-right: 2px solid #333;
	border-left: none;
}

.hc-offcanvas-nav span.nav-next::before {
	transform: translate(-50%, -50%) rotate(135deg)
}

.hc-offcanvas-nav li.nav-back span::before {
	transform: translate(-50%, -50%) rotate(-45deg)
}

.hc-offcanvas-nav.nav-position-left.nav-open .nav-wrapper {
	box-shadow: 1px 0 2px rgba(0, 0, 0, 0.2)
}

.hc-offcanvas-nav.nav-position-right.nav-open .nav-wrapper {
	box-shadow: -1px 0 2px rgba(0, 0, 0, 0.2)
}

.hc-offcanvas-nav.nav-position-right span.nav-next::before {
	margin-left: 0;
	margin-right: -2px;
	transform: translate(-50%, -50%) rotate(-45deg)
}

.hc-offcanvas-nav.nav-position-right li.nav-back span::before {
	margin-left: 0;
	margin-right: -2px;
	transform: translate(-50%, -50%) rotate(135deg)
}

.hc-offcanvas-nav.nav-position-top.nav-open .nav-wrapper {
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2)
}

.hc-offcanvas-nav.nav-position-top span.nav-next::before {
	margin-left: 0;
	margin-right: -2px;
	transform: translate(-50%, -50%) rotate(-135deg)
}

.hc-offcanvas-nav.nav-position-top li.nav-back span::before {
	margin-left: 0;
	margin-right: -2px;
	transform: translate(-50%, -50%) rotate(45deg)
}

.hc-offcanvas-nav.nav-position-bottom.nav-open .nav-wrapper {
	box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.2)
}

.hc-offcanvas-nav.nav-position-bottom span.nav-next::before {
	margin-left: 0;
	margin-right: -2px;
	transform: translate(-50%, -50%) rotate(45deg)
}

.hc-offcanvas-nav.nav-position-bottom li.nav-back span::before {
	margin-left: 0;
	margin-right: -2px;
	transform: translate(-50%, -50%) rotate(-135deg)
}

.hc-offcanvas-nav.nav-levels-expand .nav-container ul .nav-wrapper,
.hc-offcanvas-nav.nav-levels-none .nav-container ul .nav-wrapper {
	box-shadow: none;
	background: transparent
}

.hc-offcanvas-nav.nav-levels-expand .nav-container ul h2,
.hc-offcanvas-nav.nav-levels-none .nav-container ul h2 {
	display: none
}

.hc-offcanvas-nav.nav-levels-expand .nav-container ul ul a,
.hc-offcanvas-nav.nav-levels-none .nav-container ul ul a {
	font-size: 14px
}

.hc-offcanvas-nav.nav-levels-expand .nav-container li,
.hc-offcanvas-nav.nav-levels-none .nav-container li {
	/* transition: background .3s ease */
}

.hc-offcanvas-nav.nav-levels-expand .nav-container li.level-open,
.hc-offcanvas-nav.nav-levels-none .nav-container li.level-open {
}

.hc-offcanvas-nav.nav-levels-expand .nav-container li.level-open a,
.hc-offcanvas-nav.nav-levels-none .nav-container li.level-open a {
}

.nav-bar-name {
	font-weight: bold;
	width: 170px;
	word-break: break-all;
}
@use '../../variables' as css;

.calendar-custom {

    .container-input {
        * {
            cursor: pointer;
        }
       
        input {
            cursor: pointer;
            border: 1px solid #DADDE2;
            border-radius: 4px;
            height: 48px;
            width: 100%;
            padding-left: 15px;
            padding-right: 10px;
        }
    }

    .container-calender {
        position: relative;
        padding-left: 0px !important;
        z-index: 1000;

        .calendar, .calendar-picker {
            border: 1px solid lightgray;
            border-radius: 10px;
            position: absolute;
            width: fit-content;
            min-width: 300px;
            max-width: 500px;
        }

        .calendar-picker {
            min-width: 600px;
            max-width: max-content;

            .rdrDefinedRangesWrapper {
                display: none;
            }
        }
    }

    .btn-clear-input {
        margin-right: 35px !important;
        text-align: center;
        
    }

    .btn-mutil-clear-input {
        margin-top: -37px !important;
    }

    // css icon as button
    .btn-icon-input {
        width: fit-content;
        border: none;
        background-color: inherit;
        font-size: 20px;
        height: 30px;
        float: right;
        position: relative;
        margin-right: 10px;
        margin-top: -38px;
        z-index: 2;
       
    }
}

.modal-only-view-content {
    .calendar-custom {
        .container-calender {
            .calendar, .calendar-picker{
                position: fixed;
            }
        }

        &.calendar-add-attendance-code {
            .rdrCalendarWrapper.calendar {
                bottom: 20px;
            }
        }
    }
}

.calendar-input {
    border: 1px solid #DADDE2;
    border-radius: 4px;
    height: 48px;
    padding-left: 15px;
    padding-right: 10px;
}
.add-ticket-plan-modal {

    .btn-select-ticket-plans {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 20px;

        .btn-select-ticket-plan {
            background: #fff;
            border-radius: 30px;
            display: inline-block;
            font: normal normal 700 16px/26px Yu Gothic;
            height: 60px;
            letter-spacing: 0;
            width: 100%;

            &.btn-select-ticket-plan-1 {
                color: #0077b6;
                border: 1px solid #0077b6;

                &:hover {
                    background-color: #0077b6;
                    color: white;
                }
            }

            &.btn-select-ticket-plan-2 {
                color: #fcb900;
                border: 1px solid #fcb900;

                &:hover {
                    background-color: #fcb900;
                    color: white;
                }
            }
        }
    }
}

.add-attendance-code {
    .btn-join-attendance-code-confirm:disabled {
        opacity: 0.5;
    }
} 

.tooltip-custom {
    position: relative;
    display: inline-block;

    .tooltiptext {
        width: 100%;
        background-color: #1a2c44da;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        transition: opacity 0.5s ease-out;
        opacity: 0;
        height: 0;
        padding: 0px;
        overflow: hidden;
      
        /* Position the tooltip */
        position: absolute;
        z-index: 1;
        bottom: 100%;
        left: 50%;
        transform: translate(-50%, 0%);
        margin-bottom: 10px;
    }

    &:hover {
        .tooltiptext {
            opacity: 1;
            height: auto;
            padding: 5px 10px;
            overflow: visible;

            &::after {
                content: "";
                position: absolute;
                top: 100%;
                left: 50%;
                margin-left: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: #1a2c44da transparent transparent transparent;
            }
        }
    }
}
.notify {
    // border: 3px solid #1a2c44;
    border-radius: 10px;
    margin: 50px auto 0px;
    padding: 15px;

    .title {
        display: flex;
        align-items: center;

        .loudspeaker {
            width: 50px;
            margin-right: 10px;
        }

        span {
            font: normal normal bold 26px/40px Yu Gothic;
            letter-spacing: 0px;
            color: #1A2C44;
            margin: 0;
        }
    }

    .list-notify {
        padding: 5px 30px;
        height: 180px;
        overflow: overlay;

        .notify-content {
            padding: 15px 12px 15px 0px;
            // border-bottom: 1px solid #00000014;
            font-weight: bolder;
            display: flex;
            flex-wrap: nowrap;

            .notify-date {
                color: #5c5c5c;
                margin-right: 20px;
            }

            &:hover {
                cursor: pointer;

                .notify-title {
                    color: #FCB900;
                    transition: all ease 0.3s;
                }
            }
        }

        .border-bottom {
            border-bottom: 1px solid rgba(0, 0, 0, 0.0784313725);
        }
    }
}

.banner-mobile {
    display: none !important;
}

@media only screen and (max-width: 736px) { 

    .banner-web {
        display: none !important;
    }

    .banner-mobile {
        display: inline-block !important;
    }
}

@media only screen and (max-width: 768px) { 

    .notify {
        width: 90%;
    }
}
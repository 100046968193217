.loading, .loading-src {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: rgba(0, 0, 0, 0.8);
    z-index: 99999;
    
    .spinner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.loading-src {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: #fff;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;


    .progress {
        margin-top: 20px;
        width: 300px;
        height: 5px;

        .progress-bar {
            background-color: #1A2C44;
        }
    }

    .image-logo {
        width: 150px;
        max-width: 300px;
        animation: zoom-out 2s linear forwards;
        transform-origin: bottom;
    }

    @keyframes zoom-out {
        0%{
            transform: scale(1, 1);
        }

        50%{
            transform: scale(1.5, 1.5);
        }

        100%{
            transform: scale(2, 2);
        }
    }

    &.hide {
        display: none !important;
    }
}


@font-face {
  font-family: 'Yu Gothic, serif';
  src: url('YuGothR.ttc');
      
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'YuGoth Medium';
  src: url('YuGothM.ttc');
      
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'YuGoth Bold';
  src: url('YuGothB.ttc');
      
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'YuGothL';
  src: url('YuGothL.ttc');
      
  font-weight: normal;
  font-style: normal;
}











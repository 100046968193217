@media only screen and (max-width: 1600px) {
	header.header-after-login .menu-header ul li a {
		padding: 0 10px;
	}
}

@media only screen and (max-width: 1560px) {

	/* header .main-logo{
	width: 140px
}
header .menu-header {
    width: calc(100% - 140px);
   
} */
	header.header-after-login .menu-header ul li a {
		font-size: 14px
	}

	header.header-after-login .menu-header ul li.li-date a {
		font-size: 13px
	}

	header.header-after-login .menu-header ul li.li-date a {
		padding: 0 5px;
	}

	header.header-after-login .menu-header ul li.li-last a .nav-icon b {
		font-size: 14px
	}

	header.header-after-login .menu-header ul li.li-last a .nav-icon {
		font-size: 14px
	}


	header.header-after-login .menu-header ul li.li-last a .icon {
		width: 35px;
		margin-right: 5px;
	}

	/* header.header-after-login .menu-header ul li a.click-bell {
		min-width: 40px;
		padding: 0 5px;
	}

	header.header-after-login .menu-header ul li a.click-bell span {
		right: 12px
	} */
}

@media only screen and (max-width: 1024px) {
	header .menu-header ul li .register-login {
		width: auto;
		padding: 0 20px;
	}

	header .menu-header ul li .register-login {
		height: 42px;
		line-height: 42px;
	}

	header .main-logo {
		width: 185px
	}

	header .menu-header {
		width: calc(100% - 185px);
	}

	header .menu-header ul li a {
		padding: 0 15px;
	}

	header .menu-header ul li .register-login-2 {
		margin-left: 5px
	}

	header {
		padding-left: 18px
	}

	/* .Banner-Home .img-banner {
		width: calc(100% - 100px);
	} */

	.Banner-Home .img-banner img {
		/* height: 450px */
	}

	.Banner-Home .content-banner {
		height: 220px;
		bottom: -100px !important;
	}

	.Banner-Home:after {
		width: 456px;
		height: 718px;
	}

	.course-home .slider-course .owl-nav {
		right: 450px
	}

	.Price-home .item h3 {

		padding: 14px 10px 9px 10px
	}

	.Price-home .item h3 {
		font-size: 20px
	}

	.Price-home .item .nav-item {
		padding: 15px;
	}

	.section-fqa .title-primary {
		font-size: 35px;
	}

	.main-student-list .top-student-list .content-top-student {
		padding: 20px 50px 20px 20px !important;
	}

	.tool-certificate-screen .top-student-list .content-top-student {
        padding: 30px !important;
    }

	.main-student-list .top-student-list .content-top-student .row .col-md-6 {
		width: 100%;
		padding-right: 0px !important;
	}

	.main-Attended-couse .top-student-list .first-filter {
		display: flex !important;
		flex-direction: row !important;
	}

	.main-Attended-couse .top-student-list .first-filter label {
		margin-bottom: 10px;
	}

	.main-Attended-couse .top-student-list .content-top-student .item .select-status-student {
		width: 68%;
		display: block;
	}

	.main-Attended-couse .top-student-list .content-top-student .item .list-check {
		padding-left: 0px !important;
	}
	

	.main-Attended-couse .content-student-list .col-md-5 {
		width: 30%;
	}
	.main-Attended-couse .content-student-list .col-md-7 {
		width: 70%;
	}

	.main-student-list .content-student-list .table-table table {
		display: block;
		overflow: scroll;
		width: 100%
	}

	.main-Attended-couse .top-student-list .content-top-student .item-22 {
		padding-left: 5px;
	}

	.main-student-list .content-student-list .filter-list ul {
		display: block;
	}


	.main-student-list .content-student-list .table-table tr td {
		width: 100px;
	}

	.main-student-list .content-student-list .table-table tr:first-child td {
		min-width: 220px
	}

	.main-student-list .content-student-list .table-table tr:first-child td:first-child {
		min-width: 80px
	}

		
	.main-account-trgisstration-2 .box-form-account .item .item-left {
		width: 100%;
	}

	.main-account-trgisstration-2 .box-form-account .item .item-right {
		width: 100%;
	}

	.main-account-trgisstration-2 .box-form-account .item {
		display: inline-block;
	}
	.box-form-account .content-content .item .row .col-sm-6{
		display: inline-block !important;
		
	}
}

@media only screen and (max-width: 1280px) {
	header .main-logo {
		width: 100%;
		margin: 0;
		text-align: center;
	}

	header .menu-header {
		display: none;

	}

	.second-nav {
		display: block;
	}

	.hc-nav-trigger.hc-nav-1 {
		display: block !important;
	}

	header {
		padding-left: 0;
		padding: 8px 0;
		/* margin-bottom: 10px; */
	}

	.Banner-Home .content-banner {
		padding: 25px;
	}

	.Banner-Home .content-banner {
		width: 520px;
		bottom: -140px;
	}

	.Banner-Home .content-banner .title-1 {
		font-size: 24px;
		line-height: 35px
	}

	/* .Banner-Home .img-banner {
		width: calc(100% - 50px);
	} */

	.top-content .content-top-content .list-item ul li a {
		font-size: 16px;
	}

	.course-home {
		padding-top: 122px;
	}

	.title-primary {
		font-size: 35px;
	}

	.course-home .content-course-home .content-absolute {
		width: 1000px
	}

	.new-home .row {
		margin-left: -10px;
		margin-right: -10px;
	}

	.new-home .row .col-md-4 {
		padding-left: 10px;
		padding-right: 10px
	}

	/* .course-home .item .img img {
		height: 179px
	} */

	.new-home .item {
		margin-bottom: 35px;
	}

	.Price-home {
		margin-top: 67px;
		padding-top: 80px;
	}

	.section-fqa .section-fqa-right {
		padding-left: 0;
	}

	#Footer {
		padding-top: 50px
	}

	/* #Footer .top-footer{
		width: 100%;
	} */
	.main-account-trgisstration-1 .step-step {
		width: 100%
	}

	.main-view-student-detail .content-student-detail {
		width: 100%;
	}

	.menu-header-mobile {
		display: block !important;
	}

	header .menu-header.menu-header-mobile {
		width: 100%
	}

	header .menu-header ul li .content-bell {

		right: 0;
		width: 100%;
		top: 45px;
	}

	header .arrow-top:before {
		display: none;
	}

	header .menu-header ul li .content-bell {
		border-radius: 0px 0px 10px 10px;
		box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
	}

	.logo-main {
		position: relative;
		z-index: 999
	}
}

@media only screen and (max-width: 1200px) {
	.video-preview video, .video-preview .video-js {
		width: 100% !important;
		margin: auto !important;
	}
}
@media only screen and (max-width: 1200px) {
	.main-purchasse-history .item-purchasse .nav-item-purchasse .item .item-1,
	.main-purchasse-history .item-purchasse .nav-item-purchasse .item .item-2 {
		width: 50%;
		padding-left: 0px;
		padding-right: 0px;
	}

	.main-purchasse-history .item-purchasse .nav-item-purchasse .item .item-2 {
		padding-left: 30px;
	}
}

@media only screen and (max-width: 991px) {
	.main-purchasse-history .item-purchasse .nav-item-purchasse .item .item-1 {
		width: 100%;
	}

	.main-purchasse-history .item-purchasse .nav-item-purchasse .item .item-1 .span-2 {
		text-align: right;
	}

	.main-purchasse-history .item-purchasse .nav-item-purchasse .item .item-2 {
		width: 100%;
		padding-left: 0px;
	}

	.main-purchasse-history .item-purchasse .nav-item-purchasse .link-link {
		justify-content: center;
	}

	.main-purchasse-history .top-top form {
		width: 100%;
		float: none
	}

	.main-purchasse-history .top-top form input[type=text] {
		width: 100%
	}

	.main-purchasse-history .top-top .col-md-6 {
		width: 100%;
	}

	.main-detail-count-info .top-student-list .col-xl-2 {
		width: 100%;
	}

	.main-detail-count-info .top-student-list .col-xl-10 {
		width: 100%;
	}
}

@media only screen and (max-width: 812px) {
	#Footer .top-footer{
		width: 100%;
	}
}

@media only screen and (max-width: 768px) {
	.main-account-trgisstration-2 .box-form-account .item .item-left {
		width: 100%;
	}

	.main-account-trgisstration-2 .box-form-account .item .item-right {
		width: 100%;
	}

	.main-account-trgisstration-2 .box-form-account .item {
		display: inline-block;
	}
	.box-form-account .content-content .item .row .col-sm-6{
		display: inline-block !important;
		
	}

	#modal-message-popup .message-popup-container .message-popup-footer {
		display: flex !important;
		flex-direction: column;
		padding: 15px;
		gap: 8px;
	}

	#modal-message-popup .message-popup-container .message-popup-footer div, #modal-message-popup .message-popup-container .message-popup-footer button {
		width: 100%;
		text-align: center;
	}
}


@media only screen and (max-width: 800px) {
	.main-account-trgisstration-2 .box-form-account .item .item-left {
		width: 100%;
	}

	.main-account-trgisstration-2 .box-form-account .item .item-right {
		width: 100%;
	}

	.main-account-trgisstration-2 .box-form-account .item {
		display: inline-block;
	}
	.box-form-account .content-content .item .row .col-sm-6{
		display: inline-block !important;
		
	}
	.main-account-trgisstration-2 .box-form-account {
		padding: 60px 25px !important;
	}

	.icon-verify-update-profile {
		padding-left: 5px !important;
	}

	.icon-verify-update-profile img {
		width: 15px;
	}

	.main-student-list .top-student-list .content-top-student .row .col-md-6 {
		width: 100%;
		padding-right: 0px !important;
	}

	.main-Attended-couse .top-student-list .first-filter,
	.main-Attended-couse .top-student-list .content-top-student .item-22:not(.ignore-css) {
		display: flex !important;
		flex-direction: column !important;
	}

	.main-Attended-couse .top-student-list .content-top-student .item-22 {
		padding-left: 5px;
	}

	.main-Attended-couse .top-student-list .content-top-student .item-22:not(.ignore-css) label {
		margin-bottom: 10px;
	}

	.attendance-history-screen .top-student-list .content-top-student .custom-item-22 label {
		margin-bottom: 0px;
	}

	.main-Attended-couse .top-student-list .content-top-student .item-22:not(.ignore-css) input {
		margin-bottom: 10px;
	}

	.main-Attended-couse .top-student-list .content-top-student .item .list-check li {
		width: 33%;
	}

	.attendance-history-screen .top-student-list .content-top-student .custom-item-22 .list-check li {
		width: 120px;
	}

	.main-Attended-couse .top-student-list .first-filter label {
		margin-bottom: 10px;
	}

	.main-Attended-couse .top-student-list .content-top-student .item .select-status-student {
		width: 100%;
		display: block;
	}

	.main-Attended-couse .top-student-list .content-top-student .item .list-check:not(.ignore-css) {
		flex-wrap: nowrap;
		width: 100%;
	}
	

	.main-Attended-couse .content-student-list .col-md-5,
	.main-Attended-couse .content-student-list .col-md-7 {
		width: 100%;
	}

	.main-Attended-couse .content-student-list .table-table table {
		display: block;
		overflow: scroll;
		width: 100%
	}

	.main-Attended-couse .content-student-list .filter-list ul {
		display: block;
	}

	.main-Attended-couse .content-student-list .filter-list li {
		display: block;
		width: 100%;
		text-align: left;
	}

	.main-Attended-couse .content-student-list .filter-list li a {
		padding-left: 0 !important;
		margin-left: 0 !important;
	}

	.attendance-history-screen .content-student-list .filter-list li a {
		padding: 0px 8px !important;
		margin-left: 0 !important;
	}

	.main-Attended-couse .content-student-list .table-table tr td {
		width: 100px;
	}

	.main-Attended-couse .content-student-list .table-table tr:first-child td {
		min-width: 220px
	}

	.main-Attended-couse .content-student-list .table-table tr:first-child td:first-child {
		min-width: 80px
	}

	.tool-certificate-screen .container {
		max-width: 95% !important;
	}
}

@media only screen and (max-width: 736px) {
	.Banner-Home .img-banner {
		width: 100%;
	}

	.Banner-Home .content-banner {
		width: calc(100% - 20px);
		display: inline-block;
		bottom: 0px !important;
		margin: auto;
		margin-left: 10px;
		margin-top: -80px;
		position: relative;
		padding: 40px;
	}

	.Banner-Home:after {
		display: none;
	}

	.Banner-Home .content-banner .title-1 {
		font-size: 26px;
		line-height: 32px;
	}

	/* .Banner-Home .content-banner .desc {
		font-size: 15px;
	} */

	.top-content {
		padding-top: 35px;
	}

	.title-primary {
		font-size: 30px;
		line-height: 35px;
		margin-bottom: 30px;
	}

	.top-content .content-top-content {
		margin-top: 25px;
	}

	.top-content .content-top-content .theme-item, .title-sample-video {
		font-size: 15px;
	}

	.top-content .content-top-content .theme-item, .title-sample-video {
		margin-bottom: 6px
	}

	.top-content .content-top-content {
		border-left: 2px solid #FCB900;
		padding-left: 15px;
	}

	.course-home {
		padding-top: 35px;
	}

	.course-home .slider-course {
		margin-top: 6px;
	}

	.course-home .content-course-home .content-absolute {
		position: static;
	}

	.container {
		width: 100%;
	}

	.course-home .content-course-home .content-absolute {
		width: 600px
	}

	.course-home .slider-course .owl-nav {
		display: none
	}

	.slider-course .owl-dots {
		padding-right: 255px;
	}

	.course-home .item.item-readMore {
		display: none
	}

	.new-home .title-primary {
		margin-bottom: 30px;
	}

	.course-home .content-course-home {
		min-height: inherit;
	}

	/* .course-home .item .img img {
		height: 179px;
	} */

	.Price-home .description {
		font-size: 16px;
	}

	.Price-home .slider-price .owl-nav {
		display: none
	}

	.Price-home {
		overflow: hidden;
	}

	/* .Price-home  .content-price{
	width: 600px
} */
	.section-fqa {
		padding-top: 35px;
	}

	.section-fqa .section-fqa-right {
		margin-top: 20px;
	}

	#Footer {
		margin-top: 40px;
	}

	.btn-left-slide {
		display: none;
	}

	.btn-right-slide {
		display: none;
	}

	.section-fqa {
		padding-bottom: 50px
	}

	.main-signin .content-signin .content-form {
		width: 100%;
	}

	.main-signin .content-signin form {
		margin-top: 5px;
	}

	.main-signin .content-signin .item-1 {
		margin-bottom: 12px;
	}

	.main-signin .content-signin {
		padding-top: 6px;
	}

	.main-signin {
		padding-bottom: 30px;
	}

	.main-account-trgisstration-1 .content-content .link-button button {
		width: 100%;
		margin-right: 0;
		margin-bottom: 15px
	}

	.main-account-trgisstration-1 .box-form-account .content-content {
		width: 100%;
	}

	.main-account-trgisstration-1 .box-form-account {
		padding: 20px;
	}

	.main-account-trgisstration-2 .box-form-account {
		padding: 20px;
	}

	.main-account-trgisstration-2 .box-form-account .item .item-left {
		width: 100%;
	}

	.main-account-trgisstration-2 .box-form-account .item .item-right {
		width: 100%;
	}

	.main-account-trgisstration-2 .box-form-account .item {
		display: inline-block;
	}

	.main-account-trgisstration-2 .box-form-account .item {
		margin-bottom: 15px;
		width: 100%;
	}

	.main-account-trgisstration-2 .prev-next {
		margin-top: 25px;
	}

	.main-account-trgisstration-2 {
		padding-bottom: 20px;
	}

	.prev-next .next a {
		width: 160px;
	}

	.prev-next button {
		width: 160px !important;
	}

	.main-account-trgisstration-2 .box-form-account .note-2 {
		margin-top: 10px;
	}

	.main-account-trgisstration-1 .step-step {
		margin-top: 6px;
	}

	.main-account-trgisstration-1 .form-account {
		margin-top: 17px;
	}

	.main-account-trgisstration-1 .box-form-account {
		margin-top: 10px
	}

	.main-account-trgisstration-3 .content-trgisstration-3 {
		margin-top: 25px
	}

	.main-account-trgisstration-3 .content-trgisstration-3 .desc {
		font-size: 18px;
		line-height: 26px;
		margin-bottom: 0
	}

	.main-account-trgisstration-3 {
		padding-bottom: 20px;
	}

	.main-account-trgisstration-4 .box-form-account .item .row {
		margin-left: -5px;
		margin-right: -5px;
	}

	.main-account-trgisstration-4 .box-form-account .item .row .col-md-6 {
		padding-left: 5px;
		padding-right: 5px;
	}

	.main-account-trgisstration-4 .box-form-account .item .item-right .item-2 .icon {
		width: 34px;
		padding-left: 10px
	}

	.main-account-trgisstration-4 .box-form-account .item .item-right .item-2 .input {
		width: calc(100% - 34px);
		/* width: 100%; */
	}

	.main-student-list .top-student-list .content-top-student .item:not(.ignore-css) label {
		width: 100%;
		margin-right: 0;
		margin-top: 0
	}

	.main-student-list .top-student-list .content-top-student .item:not(.ignore-css) input[type=text] {
		width: 100%;
		height: 40px;
		margin-top: 5px;
	}

	.main-student-list .top-student-list .content-top-student .item .calendar-custom {
		width: 100%;
		height: 40px;
		margin-top: 5px;
	}

	.main-student-list .top-student-list .content-top-student .item .select-status-student {
		width: 100%;
		height: 40px;
		margin-top: 5px;
	}

	.main-student-list .top-student-list .content-top-student .item input[type=date] {
		width: 100%;
		height: 40px;
		margin-top: 5px;
	}

	/* .main-student-list .top-student-list .content-top-student {
		margin-top: 0
	} */

	.main-student-list .top-student-list .title-primary {
		margin-top: 20px;
	}

	.main-student-list .content-student-list .filter-list {
		text-align: center;
	}

	/* .main-student-list .content-student-list select {
		width: 100%
	} */

	.main-student-list .content-student-list .table-table {
		margin-top: 10px;
	}

	.main-student-list .content-student-list .table-table tr td:first-child {
		min-width: inherit;
	}

	.main-student-list .content-student-list .table-table tr td:nth-child(2) {
		min-width: inherit;
	}

	.pagenavi .next {
		text-align: center;
		margin-top: 10px
	}

	.pagenavi .prev {
		text-align: center;
	}

	.main-student-list {
		padding-bottom: 0;
	}

	.main-view-student-detail .content-student-list {
		padding-top: 34px;
	}

	.main-student-list.main-view-student-detail {
		padding-bottom: 30px;
	}

	.main-view-student-detail .content-student-detail .item .left {
		padding-left: 10px;
	}

	.content-view-student-detail .title-primary {
		font-size: 25px;
	}

	.main-view-student-detail .content-student-list .table-table tr td:first-child {
		min-width: 100px
	}

	.main-view-student-detail .content-student-list .table-table tr td:nth-child(2) {
		min-width: 200px
	}

	.main-view-detail-information .title-title .title-2 {
		position: static;
	}

	.main-view-detail-information.main-view-student-detail .title-title .edit {
		position: static;
	}

	.main-view-detail-information.main-view-student-detail .title-title {
		height: auto;
		border: 0;
	}

	.main-view-detail-information .title-title .title-2 {
		font-size: 28px;
	}

	.main-account-trgisstration-4.main-update .box-form-account .item .item-left {
		width: 100%;
	}

	.main-account-trgisstration-4.main-update .box-form-account .item .item-right {
		width: 100%;
	}

	.main-account-trgisstration-4.main-update .box-form-account .item .row {
		margin-left: -5px;
		margin-right: -5px;
	}

	.main-account-trgisstration-4.main-update .box-form-account .item .row .col-md-6 {
		padding-left: 5px;
		padding-right: 5px;
	}

	.main-account-trgisstration-2 .box-form-account .item {
		margin-bottom: 0;
	}

	.main-account-trgisstration-1.main-update .form-account {
		margin-top: 10px;
	}

	.main-update .link-button {
		margin-top: 25px;

	}

	.main-update .link-button .btn-1 {
		/* width: auto;
		height: auto; */
		padding: 7px 10px;
	}

	.main-update .link-button .btn-2 {
		/* width: auto;
		height: auto; */
		padding: 7px 10px;
	}

	.main-update .link-button {
		display: flex;
		justify-content: center;
	}

	.main-update .link-button .btn-2 {
		margin-left: 5px;
	}

	.main-student-list .top-student-list .title-primary {
		margin-bottom: 10px;
	}

	.main-update .link-button button {
		font-size: 13px;
	}

	.main-view-student-detail.main-update {
		padding-bottom: 20px;
	}

	.main-detail-count-info .top-student-list .col-xl-2 {
		width: 100%;
	}

	.main-detail-count-info .top-student-list .col-xl-10 {
		width: 100%;
	}

	.main-view-student-detail.main-view-detail-information .top-student-list {
		padding-top: 0;
	}

	.main-view-detail-information.main-view-student-detail .title-title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 20px 0px 30px;
	}

	.main-view-detail-information.main-view-student-detail .title-title .edit {
		padding: 0px;
	}

	.main-detail-count-info .content-student-detail {
		margin-top: 15px;

	}

	.main-update-acount .content-signin .col-md-2 {
		width: 100%;
	}

	.main-update-acount .content-signin .col-md-10 {
		width: 100%;
	}

	.main-update-acount.main-update .box-form-account .item .item-right ul li:first-child {
		margin-right: 10px;
	}

	.main-Attended-couse .top-student-list .col-md-2 {
		width: 100%;
	}

	.main-Attended-couse .top-student-list .col-md-10 {
		width: 100%;
	}

	.main-Attended-couse .top-student-list .content-top-student .item:not(.ignore-css) .list-check {
		padding: 0;
		width: 100%;
		justify-content: start;
	}

	.main-Attended-couse .top-student-list .content-top-student .item:not(.ignore-css) .list-check li {
		margin-right: 20px;
		margin-bottom: 10px;
	}

	.main-Attended-couse .content-student-list .table-table tr td {
		width: 80px;
	}

	.main-Attended-couse .content-student-list .table-table tr td:nth-child(2) {
		min-width: 148px;
	}

	.main-purchasse-history .item-purchasse .nav-item-purchasse .item .item-3 {
		width: 100%;
		padding-left: 0;
	}

	.main-view-course .content-view-course>.container>.row>.col-md-2 {
		width: 100%;
	}

	.main-view-course .content-view-course>.container>.row>.col-md-10 {
		width: 100%;
	}

	.sidebar-primary form input[type="text"] {
		width: 100%;
	}

	.main-view-course .content-view-course .view-course-right {
		padding-left: 0;
		padding-top: 15px;
	}

	.main-view-course .content-view-course .title-search form {
		margin-top: 15px;
	}

	.main-view-course .content-view-course .title-search form {
		width: 100%;
		float: none;
	}

	.main-view-course .content-view-course .content-course-right .item .img {
		width: 100%;
	}

	.main-view-course .content-view-course .content-course-right .item .img-course {
		width: 100% !important;
	}

	.main-view-course .content-view-course .content-course-right .video-preview video,
	.main-view-course .content-view-course .content-course-right .video-preview .video-js {
		width: 100% !important;
		margin: auto !important;
	}

	.main-view-course .content-view-course .title-search form input[type=text] {
		width: 100%;
	}

	.main-view-course .content-view-course .content-course-right .item .nav-img{
		width: 100%;
		padding-left: 0;
		padding-top: 15px;
	}

	.item-theme .btn-more-wrapper {
		width: 100% !important;
	}

	.main-course-detail .content-view-course .view-course-right .iframe-youtube iframe {
		width: 100%;
		height: auto
	}

	.main-course-detail .content-view-course .view-course-right .iframe-youtube {
		margin-top: 20px;
	}

	.main-course-detail .top-top {
		margin-top: 10px;
	}

	.main-course-detail .top-top ul li {
		margin-left: 0;
		margin-right: 15px;
	}

	#sendMessageScreenModal .modal-dialog .content-pupop .content-left {
		width: 100%;
	}

	#sendMessageScreenModal .modal-dialog .content-pupop .content-right {
		width: 100%;
		padding: 20px
	}

	#sendMessageScreenModal .modal-dialog .content-pupop .content-left ul li {
		display: inline-block;
		float: left;
		margin-right: 10px;
	}

	#sendMessageScreenModal .modal-dialog .content-pupop .content-left ul {
		display: inline-block;
		width: 100%;
		margin-bottom: 18px;
	}

	.main-fqa .row>.col-md-2 {
		width: 100%;
	}

	.main-fqa .row>.col-md-10 {
		width: 100%;
	}

	.main-send-inquiry.main-account-trgisstration-4 .box-form-account .item {
		padding-bottom: 10px;
	}

	#chartdiv {
		width: 100%;
	}

	.main-view-course .content-view-course {
		padding-top: 5px;
	}

	.main-view-course .content-view-course .view-course-right .title-primary {
		font-size: 30px
	}

	.main-course-detail .content-view-course .view-course-right .btn-3 {
		height: auto;
		padding: 6px;
		float: none;
		margin-top: 10px
	}

	.main-Message-list .title-filter .filter-right ul li select {
		margin: 10px 0;
	}
	.main-Message-list .title-primary {
		margin-top: 35px;
	}

	.main-Message-list .content-message .item-message-2 .date {
		font: normal normal 500 14px/26px Yu Gothic;
	}

	.main-Message-list .content-message .item-message-2 .desc-1 {
		font: normal normal 500 15px/26px Yu Gothic;
	}

	.main-Message-list .title-filter .filter-right ul li:first-child {
		width: 100%;
	}

	.main-Message-list .title-filter .filter-right ul li.msg-seen {
		width: 100%;
	}

	.main-Message-list .title-filter .filter-right ul li.msg-seen select,
	.main-Message-list .title-filter .filter-right ul li.msg-cate select {
		width: 100%;
		margin: 10px 0;
	}

	.main-Message-list .title-filter .filter-right ul li.li-check {
		width: 50%;
		margin-left: 0px;
		text-align: left;
	}

	.main-Message-list .title-filter .filter-right ul li.li-filter {
		width: 100%;
	}

	.main-Message-list .title-filter .filter-right ul li.li-filter button {
		display: block;
		margin: auto ;
		margin-top: 10px;
	}

	.main-Message-list .content-message .item-message {
		padding-left: 47px;
	}

	.main-Message-list {
		padding-bottom: 0;
	}
	
	.main-account-trgisstration-1 .step-step {
		width: 100%;
		overflow-x: auto;
	}

	.main-account-trgisstration-1 .step-step ul{
		width: 450px;
	}

	.main-account-trgisstration-1 .step-step ul .arrow-right.active-done img {
		margin-right: 5px;
		margin-left: 5px;
	}

	.main-account-trgisstration-1 .step-step ul li {
		font-size: 13px;
		text-align: left;
		padding-left: 5px
	}

	.main-account-trgisstration-1 .step-step ul li {
		width: calc((400 / 3) - 0.1px);
	}

	.main-account-trgisstration-1 .step-step ul li:first-child {
		/* width: 40%; */
	}
	.main-account-trgisstration-1 .step-step ul li:last-child {
		width: calc((400 / 3) - 0.1px);
	}
}

@media only screen and (max-width: 667px) {
	.pc-course-period {
		display: block !important;
	}

	.pc-course-period span {
		width: inherit !important;
		float: left !important;
	}

	.pc-course-period .span-2 {
		margin-bottom: 10px;
	}
	.Banner-Home .img-banner img {
		/* height: 220px */
	}

	.Price-home .slider-price {
		margin-top: 40px;
	}

	.main-execute-completion .content-execute-completion {
		margin-top: 10px;
	}

	.main-execute-completion .nav-execute-completion .item-execute {
		padding-top: 20px;
		margin-top: 20px;
	}

	.main-execute-completion .nav-execute-completion .item-execute .list-execute {
		padding-left: 48px;
	}

	.main-execute-completion .nav-execute-completion .item-execute .title-4 {
		font-size: 16px;
		padding-left: 18px;
	}

	.main-execute-completion .link-button button {
		width: 100%;
		font-size: 14px;
		padding: 0 15px;
		margin-bottom: 10px;
	}

	.main-execute-completion .link-button {
		margin-top: 20px;
	}

	.main-execute-completion .link-button .btn-2 {
		margin-left: 0;
	}

	.main-execute-completion {
		padding-bottom: 20px;
	}

	.main-execute-completion-2 .top-title-date {
		margin-bottom: 15px;
	}

	.main-view-lecture .content-view-lecture .iframe-youtube iframe {
		width: 100%;
		height: auto;
	}

	.main-view-lecture .content-view-lecture {
		padding-top: 0px;
	}

	.main-view-lecture .content-view-lecture .iframe-youtube {
		margin-bottom: 0;
	}

	.main-view-lecture .other-slider-new {
		padding-top: 30px;
	}

	.main-execute-completion.main-view-lecture {
		padding-bottom: 0;
	}

	.main-Purchase-ticket .Price-home {
		padding-top: 5px;
	}

	.main-execute-completion.main-Purchase-ticket {
		padding-bottom: 20px;
	}

	.main-Pay-ment .content-content {
		width: 100%;
		margin-top: 25px;
	}

	.main-Pay-ment .content-Pay-ment-bottom {
		margin-top: 27px;
	}

	.main-Pay-ment .content-Pay-ment-bottom .content-1 {
		padding: 15px;
	}

	.main-Message-detail .content-Message-detail {
		padding-top: 0;
	}

	.main-Message-detail {
		padding-bottom: 20px;
	}

	#box-form-account-inquiry {
		padding: 24px 20px !important;
	}
}

@media only screen and (max-width: 414px) {
	#modal-alert .modal-body {
		min-width: 90% !important;
	}

	#modal-survey-success .text-center {
		min-width: none !important;
	}
}

@media only screen and (max-width: 1400px) {
	.main-signin:after {
		display: none;
	}
}
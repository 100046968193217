.set-password-ga-screen {
    position: absolute;
    top: 0px;
    width: 100vw;
    height: 100vh;
    background-color: white;
    padding: 0px 10px;
    z-index: 2;

    .set-password-ga-form {
        width: 100%;
        max-width: 550px;
        padding: 40px 0px;
        border-radius: 10px;
        margin: 50px auto 0px;
        box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
    }

    .title {
        font: normal normal bold 40px/65px Yu Gothic;
    }

    .warning {
        color: rgb(71, 71, 71);
    }

    .field-required::after {
        content: " *";
        color: red;
    }

    .btn-icon-input {
        width: fit-content;
        border: none;
        background-color: inherit;
        font-size: 20px;
        float: right;
        position: relative;
        margin-top: -34px;
        z-index: 2;
        margin-right: 1px;
        padding: 0px 10px;
        border-radius: 5px;
        height: 33px;
        cursor: pointer;
    }

    input:not([type='checkbox']):not([type='radio']), textarea, select {
        border: 0.5px solid #D5D5D5;
        border-radius: 5px;
        font-size: 13px;
        padding: 5px;
        outline: none;
        height: 35px;
      
        &:focus {
          border-color: #001233;
        }
    }
}

@media only screen and (max-width: 500px) {
    .set-password-ga-screen {
        .set-password-ga-form {
            padding: 40px 20px !important;

            .title {
                font-size: 36px;
            }
        }
    }
}